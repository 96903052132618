import { useMutation, useQueryClient } from '@tanstack/react-query';

export function updateData<K, T>(queryKey: string[], queryFn: (uuid: string, data: K) => Promise<T>) {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ({ uuid, data }: { uuid: string; data: K }) => queryFn(uuid, data),
        onMutate: async () => {
            await queryClient.cancelQueries({ queryKey });

            const prevData = queryClient.getQueryData(queryKey);

            return { prevData };
        },
        onError: (err: any, _, context) => {
            queryClient.setQueryData(queryKey, context?.prevData);
        },
        onSettled: async () => {
            await queryClient.invalidateQueries({ queryKey });
        },
    });
}
