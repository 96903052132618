import { Badge } from '@/components/Badge';
import { DealAssignDialog } from '@/components/Dialog/DealAssignDialog';
import { useDialog } from '@/components/Dialog/DialogHandler';
import { useStorage, useToast } from '@/hooks';
import { ChangeCatalogueVehicleStatusDialog } from '@/layouts/VehicleDetailLayout/ChangeCatalogueVehicleStatusDialog';
import {
    useCatalogueVehicleAssignedAgentMutation,
    usePostCatalogueVehicleStatusChange,
} from '@/layouts/VehicleDetailLayout/useVehicleDetailQuery';
import { statusColors } from '@/lib/shared/CatalogueVehicleData';
import { getAmount } from '@/lib/utils/amount';
import { Roles, useProfileStore } from '@/stores';
import { Agent, DealReservationResponseV2 } from '@/types';
import {
    ChangeCatalogueVehicleStatusProps,
    GetCatalogueVehicleDetails,
    catalogueVehicleMainStatuses,
    catalogueVehicleSubStatuses,
    sourcesImagesLinks,
} from '@/types/Vehicle';
import { PublicationDetailButton } from '@/views/shared/vehicles/publication';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Tooltip } from 'flowbite-react';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { BiEdit } from 'react-icons/bi';
import { FaCar } from 'react-icons/fa';
import { MdKeyboardDoubleArrowDown } from 'react-icons/md';
import { TbLockDollar } from 'react-icons/tb';

export function CatalogueVehicleDetailHeader({
    catalogueVehicleDetails,
    reservation,
}: {
    readonly catalogueVehicleDetails: GetCatalogueVehicleDetails;
    readonly reservation?: DealReservationResponseV2;
}) {
    const { uuid, status, subStatus } = catalogueVehicleDetails;
    const dialog = useDialog();
    const router = useRouter();
    const { setItem, getItem } = useStorage();
    const { successToast, errorToast } = useToast();
    const { hasRequiredRoles, isAdmin } = useProfileStore();
    const { mutateAsync: postCatalogueVehicleStatusMutation } = usePostCatalogueVehicleStatusChange();
    const { mutateAsync: updateAssignedAgents } = useCatalogueVehicleAssignedAgentMutation();

    const [isExpanded, setIsExpanded] = useState(getItem('isVehicleHeaderExpanded') === 'true');

    const handleChangeStatus = async (data: ChangeCatalogueVehicleStatusProps) => {
        try {
            await postCatalogueVehicleStatusMutation({
                uuid,
                data,
            });
            successToast('Estado actualizado correctamente 👍🏼');
        } catch (error: any) {
            errorToast(error.message);
        }
    };

    const handleOpenStatusModal = async () => {
        dialog({
            type: 'modal',
            content: (
                <ChangeCatalogueVehicleStatusDialog catalogueVehicleUuid={uuid} changeStatus={handleChangeStatus} />
            ),
        });
    };

    const displayPropertyRow = () => {
        const secondRowProperties = [
            { value: catalogueVehicleDetails?.color?.name },
            { value: catalogueVehicleDetails?.power, units: 'CV' },
            { value: catalogueVehicleDetails?.mileage, units: 'kms' },
            {
                value: catalogueVehicleDetails?.registrationDate
                    ? format(new Date(catalogueVehicleDetails?.registrationDate), 'dd/MM/yyyy', { locale: es })
                    : undefined,
                label: 'Matriculación',
            },
            { value: getAmount(catalogueVehicleDetails?.agreedPrices.sellingPrice), label: 'Financiado', units: '€' },
            {
                value: getAmount(catalogueVehicleDetails?.agreedPrices.sellingCountedPrice),
                label: 'Al contado',
                units: '€',
            },
        ];

        let numProperties = 0;

        const properties = secondRowProperties.map((prop, index) => {
            if (prop.value && prop.value !== ' ') {
                numProperties++;
                return (
                    <>
                        {index !== 0 && '|'}
                        <VehicleProperty value={prop.value} label={prop.label} units={prop.units} key={prop.label} />
                    </>
                );
            }
        });

        return (
            <div className="flex gap-6">
                {numProperties > 0 ? (
                    properties
                ) : (
                    <span className="text-xs">El vehículo no tiene datos adicionales</span>
                )}
            </div>
        );
    };

    const getCatalogsLinks = () => {
        if (!catalogueVehicleDetails.managedAds) {
            return null;
        }

        return (
            <div className="flex items-center gap-2">
                {catalogueVehicleDetails.managedAds.map((ad) => (
                    <a
                        href={ad.link}
                        target="_blank"
                        className="underline text-blue-500"
                        key={ad.uuid}
                        title={ad.source}
                    >
                        <Image src={sourcesImagesLinks[ad.source]} width={20} height={20} alt={ad.source} />
                    </a>
                ))}
            </div>
        );
    };

    const handleOpenAssignModal = async (roles: Roles, agent: Agent | null, selfAssign?: boolean) => {
        dialog({
            type: 'modal',
            content: (
                <DealAssignDialog
                    role={roles}
                    agent={agent}
                    onConfirm={handleAssignAgentToDeal}
                    selfAssign={selfAssign}
                />
            ),
        });
    };

    const handleAssignAgentToDeal = async (agentUuid: string | null) => {
        try {
            await updateAssignedAgents({
                vehicleUuid: catalogueVehicleDetails.uuid,
                agents: {
                    financeAgentExternalId: null,
                    certifierAgentExternalId: null,
                    salesAgentExternalId: null,
                    processorAgentExternalId: agentUuid,
                },
            });

            dialog({
                type: 'toast',
                variant: 'success',
                content: catalogueVehicleDetails.processorAgent
                    ? 'Asignación quitada con éxito 👍'
                    : 'Vehículo asignado con éxito 👍',
            });
        } catch (e: any) {
            errorToast(e.message);
        }
    };

    return (
        <div className="w-full divide-y sticky top-0 z-10">
            <section className="flex border-separate items-center justify-between gap-6 bg-white px-5 py-2">
                <div className="flex items-center gap-6 text-sm font-extrabold">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            setIsExpanded(!isExpanded);
                            setItem('isVehicleHeaderExpanded', isExpanded ? 'false' : 'true');
                        }}
                    >
                        {isExpanded ? (
                            <MdKeyboardDoubleArrowDown className="h-5 w-5 p-0.5 text-gray-800 rounded-md hover:bg-gray-100 transform rotate-180" />
                        ) : (
                            <MdKeyboardDoubleArrowDown className="h-5 w-5 p-0.5 text-gray-800 rounded-md hover:bg-gray-100" />
                        )}
                    </button>
                    {reservation && (
                        <Tooltip content="Vehículo reservado" style="light">
                            <button
                                className="flex gap-1 cursor-pointer justify-center py-1 px-1.5 rounded-md bg-green-300"
                                onClick={() => {
                                    router.push(`/deals/${reservation.dealUuid}/notes`);
                                }}
                            >
                                <TbLockDollar className="w-5 h-5" />
                                <p>Reservado</p>
                            </button>
                        </Tooltip>
                    )}
                    {catalogueVehicleDetails.uuid ? (
                        <Tooltip content="Ver detalles del vehículo" style="light">
                            <Link href={`/vehicles/${catalogueVehicleDetails.uuid}/info`}>
                                <FaCar className="h-6 w-6 cursor-pointer" />
                            </Link>
                        </Tooltip>
                    ) : (
                        <FaCar className="h-6 w-6" />
                    )}
                    <span>{catalogueVehicleDetails.extId ?? '-'}</span>|{' '}
                    <span>{catalogueVehicleDetails.licensePlate ?? '-'}</span>|{' '}
                    <span>
                        {catalogueVehicleDetails.make?.name ?? ''} {catalogueVehicleDetails.model?.name ?? ''}
                    </span>
                    |
                    <PublicationDetailButton uuid={uuid} status={status} subStatus={subStatus} openInNewTab={false} />
                    {getCatalogsLinks()}
                    <button
                        className={`cursor-default py-1 px-2 font-normal
                    ${hasRequiredRoles([Roles.DELIVERIES, Roles.APPRAISER]) && 'hover:bg-gray-200 hover:cursor-pointer hover:rounded-lg'}`}
                        onClick={() => {
                            if (hasRequiredRoles([Roles.DELIVERIES, Roles.APPRAISER])) {
                                handleOpenAssignModal(Roles.PROCESSOR, catalogueVehicleDetails.processorAgent);
                            }
                        }}
                    >
                        <span className="m-auto">📂️</span>
                        {catalogueVehicleDetails.processorAgent?.name ? (
                            <span className="font-semibold">{catalogueVehicleDetails.processorAgent?.name}</span>
                        ) : (
                            <span className="text-sm"> Sin Asignar</span>
                        )}
                    </button>
                </div>
                <div className="flex justify-end items-center gap-4">
                    {catalogueVehicleDetails.status && (
                        <Badge color={statusColors[catalogueVehicleDetails.status]}>
                            {catalogueVehicleMainStatuses[catalogueVehicleDetails.status]}
                        </Badge>
                    )}
                    {catalogueVehicleDetails.subStatus && (
                        <Badge color={'gray'}>{catalogueVehicleSubStatuses[catalogueVehicleDetails.subStatus]}</Badge>
                    )}
                    {(hasRequiredRoles([Roles.PROCESSOR, Roles.DELIVERIES, Roles.APPRAISER, Roles.MARKETING_AGENT]) ||
                        isAdmin()) && (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                handleOpenStatusModal();
                            }}
                        >
                            <BiEdit className="h-6 w-6 text-gray-800" />
                        </button>
                    )}
                </div>
            </section>
            {isExpanded && (
                <section className="flex border-separate items-center justify-between gap-6 bg-white px-5 py-1">
                    {displayPropertyRow()}
                </section>
            )}
        </div>
    );
}

type Value = string | number | null | undefined;

function VehicleProperty({
    label,
    value,
    units,
    key,
}: {
    readonly value: Value;
    readonly label: string | undefined;
    readonly units: string | undefined;
    readonly key: string | undefined;
}) {
    return (
        <div className="flex flex-row gap-1 items-center" key={key}>
            {label && <span className="text-xs text-gray-500">{label}:</span>}
            <span className="my-auto text-xs font-semibold text-gray-700">{value ?? '-'}</span>
            {units && <span className="text-xs font-semibold text-gray-700">{units}</span>}
        </div>
    );
}
