import { SelectOption } from '@/types';
import { Label, Select as FSelect } from 'flowbite-react';
import React, { ComponentProps, ForwardedRef } from 'react';
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';
import { BsAsterisk } from 'react-icons/bs';

interface SelectProps extends ComponentProps<'select'> {
    label?: string;
    placeholder?: string;
    options: SelectOption<string | number | boolean>[];
    error?: FieldError | Merge<FieldError, FieldErrorsImpl<any>>;
    allowEmpty?: boolean;
}

export const Select = React.forwardRef((props: SelectProps, ref: ForwardedRef<HTMLSelectElement>) => {
    const SelectTheme = {
        base: 'px-0 py-0 w-full',
        field: {
            select: {
                base: 'px-0 py-0 w-full flowbiteInput',
            },
        },
    };

    const LabelTheme = {
        root: {
            base: `text-xs font-normal ${props.disabled && 'text-gray-300'}`,
        },
    };

    const options = props.allowEmpty
        ? [
              {
                  label: '-- No especificado --',
                  value: '',
              },
              ...props.options,
          ]
        : props.options;
    return (
        <div className="flex flex-col">
            <Label theme={LabelTheme} htmlFor={props.id} color={props.error && 'failure'}>
                <div className="flex gap-1 items-center">
                    {props.label}
                    {props.required && <BsAsterisk className={props.error ? 'text-red-500' : 'text-blue-600'} />}
                </div>

                <FSelect
                    defaultValue={''}
                    {...props}
                    required={false}
                    onChange={props.onChange}
                    ref={ref}
                    theme={SelectTheme}
                >
                    {props.placeholder && (
                        <option value={''} disabled>
                            {props.placeholder}
                        </option>
                    )}
                    {options.map((option: any) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </FSelect>
            </Label>
            <span className={`mt-1 text-xs ${props.error ? 'text-red-600' : ''}`}>
                {props.error?.message?.toString()}
            </span>
        </div>
    );
});
