import { Accordion } from '@/components/Accordion';
import { Button } from '@/components/Button';
import { Select, TextInput } from '@/components/Inputs';
import { useUuid } from '@/hooks/useUuid';
import useUnsavedChangesWarning from '@/hooks/useUnsavedChangesWarning';
import { getOptions } from '@/lib/utils/getters';
import { DealBasicInfo, FormProps } from '@/types';
import { Address } from '@/types/Deal';
import { updateDealAddress } from '@/views/deals/details/info';
import { useForm } from 'react-hook-form';
import { HouseType, houseTypeOptions } from '@/types/Client/options';
import { WithGoogleMapsAutocomplete } from '@/views/shared/HOC';

export function BillingAddressForm({
    address,
    onSuccess,
    onError,
    dealBasicInfo,
}: FormProps & {
    readonly address: Address | null;
    readonly dealBasicInfo: DealBasicInfo | undefined;
}) {
    const dealUuuid = useUuid();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { isDirty, errors, isSubmitting },
    } = useForm({
        values: {
            ...address,
            houseType: address?.houseType === null ? '' : address?.houseType,
        },
        mode: 'onSubmit',
    });
    const { mutateAsync: updateAddress } = updateDealAddress();

    useUnsavedChangesWarning(isDirty, () => {
        return confirm('Parece que tienes cambios sin guardar, ¿deseas abandonar la página?');
    });

    const handleSubmitForm = handleSubmit(async (data) => {
        try {
            await updateAddress({
                uuid: dealUuuid,
                data: {
                    billingAddress: {
                        ...data,
                        city: data.city === '' ? null : data.city,
                        domicile: data.domicile === '' ? null : data.domicile,
                        houseType: data.houseType === '' ? null : (data.houseType as HouseType),
                        postalCode: data.postalCode === '' ? null : data.postalCode,
                        province: data.province === '' ? null : data.province,
                        route: data.route === '' ? null : data.route,
                        streetNumber: data.streetNumber === '' ? null : data.streetNumber,
                        complete: `${data.route}, ${data.streetNumber}, ${data.domicile}, ${data.postalCode}`,
                    },
                },
            });
            onSuccess();
        } catch (e) {
            onError();
        }
    });

    return (
        <div className="space-y-2">
            <Accordion title="Dirección de facturación" className="bg-gray-100">
                <WithGoogleMapsAutocomplete setValue={setValue}>
                    <form
                        className="grid grid-cols-3 gap-4 p-5 form-enabled"
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleSubmitForm();
                        }}
                    >
                        <Select
                            label="Tipo de vivienda"
                            placeholder="Selecciona una opción"
                            options={getOptions(houseTypeOptions)}
                            required={dealBasicInfo?.tier !== 'C2C'}
                            {...register('houseType', {
                                required: dealBasicInfo?.tier !== 'C2C' ? 'El tipo de vivienda es obligatorio' : false,
                            })}
                            error={errors.houseType}
                        />
                        <TextInput
                            label="Dirección"
                            required={dealBasicInfo?.tier !== 'C2C'}
                            {...register('route', {
                                required: dealBasicInfo?.tier !== 'C2C' ? 'La dirección es obligatoria' : false,
                            })}
                            type="text"
                            id="route"
                            error={errors.route}
                        />
                        <TextInput
                            label="Número de calle"
                            {...register('streetNumber')}
                            type="text"
                            error={errors.streetNumber}
                        />
                        <TextInput
                            label="Número de piso + puerta"
                            {...register('domicile')}
                            type="text"
                            error={errors.domicile}
                        />

                        <TextInput
                            label="Localidad"
                            required={dealBasicInfo?.tier !== 'C2C'}
                            {...register('city', {
                                required: dealBasicInfo?.tier !== 'C2C' ? 'La localidad es obligatoria' : false,
                            })}
                            type="text"
                            error={errors.city}
                        />
                        <TextInput
                            label="Provincia"
                            required={dealBasicInfo?.tier !== 'C2C'}
                            {...register('province', {
                                required: dealBasicInfo?.tier !== 'C2C' ? 'La provincia es obligatoria' : false,
                            })}
                            type="text"
                            error={errors.province}
                        />
                        <TextInput
                            label="Código postal"
                            required={dealBasicInfo?.tier !== 'C2C'}
                            {...register('postalCode', {
                                required: dealBasicInfo?.tier !== 'C2C' ? 'El código postal es obligatorio' : false,
                            })}
                            type="text"
                            error={errors.postalCode}
                        />

                        <div className="grid col-span-3 gap-10 grid-cols-6">
                            <Button
                                disabled={!isDirty || isSubmitting}
                                className=" col-span-2 col-start-5"
                                type="submit"
                                color="blue"
                            >
                                Guardar
                            </Button>
                        </div>
                    </form>
                </WithGoogleMapsAutocomplete>
            </Accordion>
        </div>
    );
}
