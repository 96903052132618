import { DocumentType } from '@/types/generics';

const DNI_REGEX = /^(\d{8})([A-Za-z])$/;
const NIE_REGEX = /^[XYZxyz]\d{7}[A-Za-z]$/;
const LETTER = 'TRWAGMYFPDXBNJZSQVHLCKE';

export function getDocumentType(documentNumber: string): DocumentType {
    if (DNI_REGEX.test(documentNumber)) {
        return 'DNI';
    }
    return 'NIE';
}

function validateNIE(documentNumber: string): boolean {
    let num, char, index;

    documentNumber = documentNumber.replace(/^[X]/, '0').replace(/^[Y]/, '1').replace(/^[Z]/, '2');

    num = documentNumber.slice(0, -1);
    char = documentNumber.slice(-1);

    index = parseInt(num) % 23;

    return LETTER[index] === char;
}

export function isValidDocument(documentNumber: string): boolean {
    if (DNI_REGEX.test(documentNumber)) {
        return documentNumber.charAt(8) == LETTER.charAt(parseInt(documentNumber.substring(0, 8), 10) % 23);
    }
    if (NIE_REGEX.test(documentNumber)) {
        return validateNIE(documentNumber);
    }
    return false;
}
