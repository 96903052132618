import { BooleanString } from '@/types/options';
import { z } from 'zod';

export function preprocessEmptyToNull<T extends z.ZodType>(schema: T): z.ZodEffects<T, z.infer<T> | null> {
    return z.preprocess((val) => {
        // Handle empty strings
        if (typeof val === 'string') {
            const trimmed = val.trim();
            return trimmed === '' ? null : trimmed;
        }

        // Handle NaN for numbers
        if (typeof val === 'number' && isNaN(val)) {
            return null;
        }

        // Handle undefined
        if (val === undefined) {
            return null;
        }

        return val;
    }, schema);
}

export function preprocessEmptyToUndefined<T extends z.ZodType>(schema: T): z.ZodEffects<T, z.infer<T> | undefined> {
    return z.preprocess((val) => {
        // Handle empty strings
        if (typeof val === 'string') {
            const trimmed = val.trim();
            return trimmed === '' ? undefined : trimmed;
        }

        // Handle NaN for numbers
        if (typeof val === 'number' && isNaN(val)) {
            return undefined;
        }

        // Handle null
        if (val === null) {
            return undefined;
        }

        return val;
    }, schema);
}

export function preprocessBooleanStringOption<T extends z.ZodType>(schema: T): z.ZodEffects<T, z.infer<T>> {
    return z.preprocess((val) => {
        if (val === BooleanString.enum.true) return true;
        if (val === BooleanString.enum.false) return false;

        return val;
    }, schema);
}
