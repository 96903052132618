import { removeSpaces } from '../utils/helpers';

const spanishPrefix = '34';
const plusSymbol = '+';
const spanishPrefixWithSymbol = plusSymbol + spanishPrefix;

const spanishPhoneNumberLength = 9;
const spanishPhoneNumberWithPrefixLength = 11;

const conditionToAddSpanishPrefix = (phone: string) =>
    phone.length === spanishPhoneNumberLength && !phone.startsWith(plusSymbol);

const conditionToAddPlusSymbol = (phone: string) =>
    phone.length === spanishPhoneNumberWithPrefixLength && !phone.startsWith(plusSymbol);

export function isValidPhoneNumber(value: string) {
    if (!value) {
        return false;
    }
    const sanitizedValue = sanitizePhone(value);
    if (!validatePhone(sanitizedValue)) {
        return false;
    }

    return true;
}

function validatePhone(value: string): boolean {
    const spanishRegExp = /^\+34[6789]\d{8}$/;
    if (value.startsWith(spanishPrefixWithSymbol)) {
        return spanishRegExp.test(value);
    }
    const genericPhoneRegExp = /^\+\d{6,14}/;
    return genericPhoneRegExp.test(value);
}

export function sanitizePhone(value: string): string {
    const phoneWithoutSpaces = removeSpaces(value);

    const phoneWithSpanishPrefix = addPrefixIfNeeded(phoneWithoutSpaces, spanishPrefix, conditionToAddSpanishPrefix);
    const phoneWithPlusPrefix = addPrefixIfNeeded(phoneWithSpanishPrefix, plusSymbol, conditionToAddPlusSymbol);

    return phoneWithPlusPrefix;
}

function addPrefixIfNeeded(str: string, prefix: string, condition: (phone: string) => boolean): string {
    if (condition(str)) {
        return prefix + str;
    }
    return str;
}
