import { PersonalDetail } from '@/types/Deal';
import { useToast, useUuid } from '@/hooks';
import { Controller, useForm } from 'react-hook-form';
import { updatePersonalDetailMutation } from '@/views';
import { zonedTimeToUtc } from 'date-fns-tz';
import { Accordion } from '@/components/Accordion';
import { Select } from '@/components/Inputs';
import { Button } from '@/components/Button';
import { LoanPurpose, loanPurposeOptions } from '@/types/Client/options';
import { getOptions } from '@/lib/utils/getters';

interface ClientInfoSectionProps {
    readonly personalDetail: PersonalDetail;
}

export function ClientInfoSection({ personalDetail }: ClientInfoSectionProps) {
    const dealUuid = useUuid();
    const { successToast, errorToast } = useToast();

    const {
        control,
        handleSubmit,
        formState: { isDirty, errors, isSubmitting },
    } = useForm({
        values: {
            loanPurpose: personalDetail?.loanPurpose ?? '',
        },
        mode: 'onSubmit',
    });

    const { mutateAsync: updatePersonalDetail } = updatePersonalDetailMutation();

    const handleSubmitForm = handleSubmit(async (data) => {
        try {
            await updatePersonalDetail({
                uuid: dealUuid,
                data: {
                    ...personalDetail,
                    dateOfBirth: personalDetail.dateOfBirth ? zonedTimeToUtc(personalDetail.dateOfBirth, 'UTC') : null,
                    loanPurpose: data.loanPurpose === '' ? null : (data.loanPurpose as LoanPurpose),
                },
            });

            successToast('Motivo del préstamo actualizado correctamente.');
        } catch (e) {
            errorToast('Error al actualizar el motivo del préstamo.');
        }
    });

    return (
        <div className="space-y-2">
            <Accordion title="Datos del cliente" className="bg-gray-100">
                <form
                    className="flex justify-between p-5 form-enabled"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmitForm();
                    }}
                >
                    <Controller
                        name="loanPurpose"
                        control={control}
                        render={({ field }) => (
                            <Select
                                label="Motivo del préstamo"
                                placeholder="Selecciona un motivo"
                                allowEmpty
                                {...field}
                                options={getOptions(loanPurposeOptions)}
                                error={errors.loanPurpose}
                            />
                        )}
                    />

                    <div className="flex justify-end items-center">
                        <Button
                            disabled={!isDirty || isSubmitting}
                            className=" col-span-2 col-start-5"
                            type="submit"
                            color="blue"
                        >
                            Guardar
                        </Button>
                    </div>
                </form>
            </Accordion>
        </div>
    );
}
