import {
    Addresses,
    BankDetail,
    CoownerDetail,
    DealInfoDataView,
    Employment,
    ForeignDetail,
    GetDealAddressesResponse,
    GetDealBankDetailResponse,
    GetDealCoownerDetailResponse,
    GetDealEmploymentResponse,
    GetDealForeignDetailResponse,
    GetDealPersonalDetailsResponse,
    GetDealVehicleReponse,
    MktDetail,
    PersonalDetail,
    PutDealAddressesRequest,
    PutDealBankDetailRequest,
    PutDealCoownerDetailRequest,
    PutDealEmploymentRequest,
    PutDealForeignDetailResponse,
    PutDealPersonalDetailsRequest,
    PutDealVehicleRequest,
    PutDealVehicleResponse,
} from '@/types/Deal';

import { useApiClient } from '@/hooks/useApiClient';
import { PutDealVehicleLicensePlateRequest } from '@/types';
import { useMutation, useQueries, useQueryClient } from '@tanstack/react-query';
import { updateData } from '@/hooks';

const client = useApiClient();

type DealInfoData =
    | { personalDetail: PersonalDetail }
    | { employment: Employment }
    | { addresses: Addresses }
    | { vehicle: GetDealVehicleReponse }
    | { foreignDetail: ForeignDetail }
    | { mktDetail: MktDetail }
    | { bankDetail: BankDetail }
    | { coownerDetail: CoownerDetail };

const PersonalDetailsInitial = {
    personalDetail: {},
    employment: {},
    vehicle: {},
    addresses: {},
    foreignDetail: {},
    mktDetail: {},
    bankDetail: {},
    coownerDetail: {},
};

const queryOptions = {
    refetchOnWindowFocus: false,
};

const queries = (uuid: string) => [
    {
        queryKey: ['dealPersonalDetail', uuid],
        queryFn: async () => {
            const data = await client.getDealPersonalDetail(uuid);
            return {
                personalDetail: data,
            };
        },
        ...queryOptions,
    },
    {
        queryKey: ['dealAddress', uuid],
        queryFn: async () => {
            const data = await client.getDealAddresses(uuid);
            return {
                addresses: {
                    billingAddress: data.billingAddress,
                },
            };
        },
        ...queryOptions,
    },
    {
        queryKey: ['dealEmployment', uuid],
        queryFn: async () => {
            const data = await client.getDealEmployment(uuid);
            return {
                employment: data,
            };
        },
        ...queryOptions,
    },
    {
        queryKey: ['dealForeignDetail', uuid],
        queryFn: async () => {
            const data = await client.getDealForeignDetail(uuid);
            return {
                foreignDetail: data,
            };
        },
        ...queryOptions,
    },
    {
        queryKey: ['dealMktDetail', uuid],
        queryFn: async () => {
            const data = await client.getDealMktDetail(uuid);
            return {
                mktDetail: data,
            };
        },
        ...queryOptions,
    },
    {
        queryKey: ['dealBankDetail', uuid],
        queryFn: async () => {
            const data = await client.getDealBankDetail(uuid);
            return {
                bankDetail: data,
            };
        },
        ...queryOptions,
    },
    {
        queryKey: ['dealCoownerDetail', uuid],
        queryFn: async () => {
            const data = await client.getDealCoownerDetail(uuid);
            return {
                coownerDetail: data,
            };
        },
        ...queryOptions,
    },
];

function transformPersonalInfo(
    result: Partial<{
        data: DealInfoData;
    }>[],
): DealInfoDataView {
    const getItem = (name: string, items: Array<any>) => {
        return (
            items?.find((item) => {
                if (item.data === undefined) return false;
                return Object.keys(item.data).includes(name);
            })?.data[name] ?? {}
        );
    };

    return {
        personalDetail: getItem('personalDetail', result),
        employment: getItem('employment', result),
        vehicle: getItem('vehicle', result),
        addresses: getItem('addresses', result),
        foreignDetail: getItem('foreignDetail', result),
        mktDetail: getItem('mktDetail', result),
        bankDetail: getItem('bankDetail', result),
        coownerDetail: getItem('coownerDetail', result),
    };
}

export const useDealInfoPageData = (uuid: string) => {
    const result = useQueries({ queries: queries(uuid) });
    const isLoading = result.some((result) => result.isLoading);

    if (!isLoading) {
        return {
            isLoading,
            isError: result.some((result) => result.isError),
            data: transformPersonalInfo(result),
        };
    }
    return {
        isLoading,
        isError: result.some((result) => result.isError),
        data: PersonalDetailsInitial,
    };
};

export const updatePersonalDetailMutation = () =>
    updateData<PutDealPersonalDetailsRequest, GetDealPersonalDetailsResponse>(
        ['dealPersonalDetail'],
        client.putDealPersonalDetail,
    );
export const updateDealEmployment = () =>
    updateData<PutDealEmploymentRequest, GetDealEmploymentResponse>(['dealEmployment'], client.putDealEmployment);
export const updateDealBankDetail = () =>
    updateData<PutDealBankDetailRequest, GetDealBankDetailResponse>(['dealBankDetail'], client.putDealBankDetail);
export const updateDealCoownerDetail = () =>
    updateData<PutDealCoownerDetailRequest, GetDealCoownerDetailResponse>(
        ['dealCoownerDetail'],
        client.putDealCoownerDetail,
    );
export const updateDealAddress = () =>
    updateData<PutDealAddressesRequest, GetDealAddressesResponse>(['dealAddress'], client.putDealAddresses);
export const updateDealForeignDetail = () =>
    updateData<PutDealForeignDetailResponse, GetDealForeignDetailResponse>(
        ['dealForeignDetail'],
        client.putDealForeignDetail,
    );

export const updateDealVehicle = () =>
    updateData<PutDealVehicleRequest, PutDealVehicleResponse>(['dealVehicle'], client.putDealVehicle);

export function usePutVehicleLicensePlateMutation() {
    const queryClient = useQueryClient();
    const changeVehicleQueryKey = ['dealVehicleLicensePlate'];

    return useMutation({
        mutationFn: async ({ dealUuid, data }: { dealUuid: string; data: PutDealVehicleLicensePlateRequest }) =>
            await client.putDealVehicleLicensePlate(dealUuid, data),
        onMutate: async (data) => {
            await queryClient.cancelQueries({ queryKey: changeVehicleQueryKey });

            const prevData = queryClient.getQueryData([changeVehicleQueryKey]);

            return { prevData };
        },
        onSettled: async (data) => {
            await queryClient.invalidateQueries({ queryKey: ['dealVehicle', data?.uuid] });
            await queryClient.resetQueries({ queryKey: ['carfax', data?.uuid] });
            await queryClient.resetQueries({ queryKey: ['dealCatalogueVehicle', data?.uuid] });
            await queryClient.invalidateQueries({ queryKey: changeVehicleQueryKey });
        },
        onError: (error: any) => {
            throw new Error(error.message);
        },
    });
}
