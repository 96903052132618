import {
    CatalogueVehicleDetailHeader,
    DealDetailHeader,
    DealDetailHeaderC2C,
    DealDetailHeaderSkeleton,
} from '@/components/Header';
import { useSubmissionsList } from '@/hooks/useSubmissionsList';
import { useUuid } from '@/hooks/useUuid';
import { ClientDataModule } from '@/layouts/DealDetailLayout/ClientDataModule';
import { ReservationModule } from '@/layouts/DealDetailLayout/ReservationModule';
import { useReservationsQuery } from '@/layouts/DealDetailLayout/useDealReservationQuery';
import { Roles, useProfileStore } from '@/stores/useProfileStore';
import { Tier } from '@/types';
import { ListGroup } from 'flowbite-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { PropsWithChildren, ReactElement } from 'react';
import { MainLayout } from '../MainLayout';
import { useGetVehicleDetailByLicensePlate } from '../VehicleDetailLayout/useVehicleDetailQuery';
import { useDealBasicInfoData } from '../useDealBasicInfoQuery';

export type SidebarItem = Record<
    string,
    {
        label: string;
        disabled?: boolean;
    }
>;

export const dealPagesMap = {
    info: {
        label: 'Datos cliente',
    },
    notes: {
        label: 'Notas',
    },
    offer: {
        label: 'Oferta',
    },
    submissions: {
        label: 'Datos financiación',
    },
    vehicle: {
        label: 'Datos vehículo',
    },
    documentation: {
        label: 'Documentación',
    },
    'amount-breakdown': {
        label: 'Desglose importes',
    },
    delivery: {
        label: 'Entrega',
    },
    renting: {
        label: 'Renting',
    },

    marketing: {
        label: 'Datos marketing',
    },
    alerts: {
        label: 'Alertas',
    },

    asnef: {
        label: 'Asnef',
    },
    aggregations: {
        label: 'Agregación Bancaria (PSD2)',
    },
    // 'activity-log': {
    //     label: 'Registro de actividad',
    // },
};

export function DealDetailLayout({ children }: PropsWithChildren<{}>) {
    const router = useRouter();
    const uuid = useUuid();
    const { isLoading, data: dealBasicInfo } = useDealBasicInfoData(uuid);

    const { data: vehicleDetails, isLoading: vehicleDetailsIsLoading } = useGetVehicleDetailByLicensePlate(
        dealBasicInfo?.vehicle?.licensePlate,
        dealBasicInfo?.tier,
    );

    const { data: submissions, isLoading: isLoadingSubmissions } = useSubmissionsList(uuid);

    const { data: reservation, isLoading: reservationIsLoading } = useReservationsQuery(
        {
            licensePlate: dealBasicInfo?.vehicle?.licensePlate,
            statuses: ['RECEIVED'],
        },
        !!dealBasicInfo?.vehicle?.licensePlate,
    );

    const { hasRequiredRoles } = useProfileStore();

    const SideNavigation: SidebarItem = {
        ...dealPagesMap,
        info: {
            ...dealPagesMap.info,
            disabled: dealBasicInfo?.tier === 'C2C',
        },
        offer: {
            ...dealPagesMap.offer,
            disabled:
                !['PAID', 'C2C'].includes(dealBasicInfo?.tier || '') ||
                !hasRequiredRoles([
                    Roles.FINANCIAL_ANALYST,
                    Roles.FINANCE_USER,
                    Roles.SALES_AGENT,
                    Roles.C2C_SALES_AGENT,
                ]),
        },
        delivery: {
            ...dealPagesMap.delivery,
            disabled: dealBasicInfo?.tier !== 'C2C',
        },
        submissions: {
            ...dealPagesMap.submissions,
            disabled:
                !['PAID', 'C2C'].includes(dealBasicInfo?.tier || '') ||
                !hasRequiredRoles([
                    Roles.FINANCIAL_ANALYST,
                    Roles.FINANCE_USER,
                    Roles.SALES_AGENT,
                    Roles.C2C_SALES_AGENT,
                    Roles.COLLECTION_AGENT,
                ]),
        },
        'amount-breakdown': {
            ...dealPagesMap['amount-breakdown'],
            disabled: dealBasicInfo?.tier !== 'C2C',
        },
        renting: {
            ...dealPagesMap.renting,
            disabled: dealBasicInfo?.tier !== 'RENT',
        },
        asnef: {
            ...dealPagesMap.asnef,
            disabled: dealBasicInfo?.tier !== 'PAID',
        },
        aggregations: {
            ...dealPagesMap.aggregations,
            disabled: dealBasicInfo?.tier == 'C2C',
        },
    };

    const isDOMComponent = (element: ReactElement) => {
        return typeof element.type === 'string';
    };

    let modifiedChildren;
    if (!isDOMComponent(children as ReactElement)) {
        modifiedChildren = React.cloneElement(
            children as ReactElement,
            !isLoading && !vehicleDetailsIsLoading ? { dealBasicInfo, vehicleDetails } : {},
        );
    } else {
        modifiedChildren = children;
    }

    const selectHeaderByTier = (tier: Tier | null | undefined) => {
        if (!dealBasicInfo) {
            return null;
        }
        if (tier === 'C2C') {
            return (
                <div className="w-full sticky top-0 z-10">
                    {vehicleDetails ? (
                        <CatalogueVehicleDetailHeader
                            catalogueVehicleDetails={vehicleDetails}
                            reservation={reservation?.items[0]}
                        />
                    ) : (
                        <div className="flex border-separate items-center bg-white px-5 py-3">
                            No se ha encontrado el vehículo asociado en el catálogo
                        </div>
                    )}

                    <DealDetailHeaderC2C
                        info={dealBasicInfo}
                        catalogueVehicleDetails={vehicleDetails}
                        submissions={submissions?.REJECTED}
                    />
                </div>
            );
        }
        return <DealDetailHeader info={dealBasicInfo} />;
    };

    return (
        <MainLayout>
            {isLoading || isLoadingSubmissions || (vehicleDetailsIsLoading && <DealDetailHeaderSkeleton />)}
            {!isLoading && !vehicleDetailsIsLoading && !isLoadingSubmissions && selectHeaderByTier(dealBasicInfo?.tier)}

            <div className="flex items-start gap-6 p-6">
                <div className={`sticky space-y-4 ${dealBasicInfo?.tier === 'C2C' ? 'top-[169px]' : 'top-[149px]'}`}>
                    <ClientDataModule dealBasicInfo={dealBasicInfo} />
                    {dealBasicInfo?.tier === 'C2C' && !reservationIsLoading && (
                        <ReservationModule reservationAssociatedWithLicensePlate={reservation?.items[0]} />
                    )}
                    <ListGroup className="min-w-[240px] divide-y">
                        {Object.keys(SideNavigation)
                            .filter((item) => !SideNavigation[item].disabled)
                            .map((item) => (
                                <ListGroup.Item
                                    key={SideNavigation[item].label}
                                    theme={{
                                        base: 'min-w-xl',
                                        link: {
                                            base: 'flex w-full shadow-transparent hover:rounded-lg',
                                        },
                                    }}
                                >
                                    <Link
                                        className={`flex w-full items-center justify-between p-3 px-4 ${
                                            router.asPath.startsWith(`/deals/${uuid}/${item}`)
                                                ? 'text-blue-500'
                                                : 'text-gray-900'
                                        }`}
                                        href={`/deals/${uuid}/${item}`}
                                    >
                                        <span>{SideNavigation[item].label}</span>
                                    </Link>
                                </ListGroup.Item>
                            ))}
                    </ListGroup>
                </div>
                <div className="flex flex-1 max-w-screen">{modifiedChildren}</div>
            </div>
        </MainLayout>
    );
}
