import { Card } from '@/components/Card';
import { SelectOption, Stage, tiers } from '@/types';
import { Statistics } from '@/types/statistics';
import { DashboardRedirectProps } from '@/views/dashboard/DashboardView';
import { FilterSalesAgentDashboardForm } from '@/views/dashboard/forms';
import { useState } from 'react';

interface PaidSectionProps {
    readonly data: Statistics;
    readonly redirectToDeals: (props: DashboardRedirectProps) => void;
}

export function PaidSection({ data, redirectToDeals }: PaidSectionProps) {
    const [salesAgent, setSalesAgent] = useState<SelectOption<string> | null>(null);
    const handleClickCard = (stage?: Stage) => {
        redirectToDeals({
            tier: tiers.PAID,
            filters: {
                situation: 'OPEN',
                ...(stage && { stage }),
                ...(salesAgent && {
                    salesAgentUuid: salesAgent?.value,
                    label: salesAgent?.label,
                }),
            },
        });
    };

    return (
        <div className="space-y-8">
            <div className="flex gap-8 items-center">
                <h2 className="font-semibold text-2xl">Ventas</h2>
                <FilterSalesAgentDashboardForm tier={tiers.PAID} setSalesAgent={setSalesAgent} />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                <Card onClick={() => handleClickCard()}>
                    <div className="flex flex-col ps-2">
                        <h2 className=" text-3xl font-bold">{data.deals.total}</h2>
                        <span className="text-sm text-gray-500">Total de deals</span>
                    </div>
                </Card>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                {data.deals.stages.map((stage) => (
                    <Card key={stage.stage} onClick={() => handleClickCard(stage.stage)}>
                        <div className="flex flex-col ps-2">
                            <h2 className="text-3xl font-bold">
                                {new Intl.NumberFormat('de-DE', { minimumIntegerDigits: 2 }).format(stage.count)}
                            </h2>
                            <span className="text-sm text-gray-500">
                                {stage.stage.toLowerCase().split('_').join(' ').charAt(0).toUpperCase() +
                                    stage.stage.toLowerCase().split('_').join(' ').slice(1)}
                            </span>
                        </div>
                    </Card>
                ))}
            </div>
        </div>
    );
}
