export const clientPagesMap = {
    info: {
        label: 'Datos clientes',
    },
    associatedLeads: {
        label: 'Leads de ventas',
    },
    documents: {
        label: 'Documentación',
    },
};
