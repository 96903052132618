import { useCallback, useEffect, useLayoutEffect, useRef } from 'react';
import { Address } from '@/types/Deal';
import Script from 'next/script';
import { FieldValues, Path, PathValue, UseFormSetValue } from 'react-hook-form';

interface WithGoogleMapsAutocompleteProps<T extends FieldValues> {
    readonly children: React.ReactNode;
    readonly setValue: UseFormSetValue<T>;
    readonly basePath?: string;
}

export function WithGoogleMapsAutocomplete<T extends FieldValues>({
    children,
    setValue,
    basePath,
}: WithGoogleMapsAutocompleteProps<T>) {
    const autocomplete = useRef<google.maps.places.Autocomplete>();

    function buildBasePath(basePath?: string) {
        return basePath ? `${basePath}.` : '';
    }

    function resetForm() {
        setValue(`${buildBasePath(basePath)}streetNumber` as Path<T>, '' as PathValue<T, Path<T>>);
        setValue(`${buildBasePath(basePath)}route` as Path<T>, '' as PathValue<T, Path<T>>);
        setValue(`${buildBasePath(basePath)}postalCode` as Path<T>, '' as PathValue<T, Path<T>>);
        setValue(`${buildBasePath(basePath)}city` as Path<T>, '' as PathValue<T, Path<T>>);
        setValue(`${buildBasePath(basePath)}province` as Path<T>, '' as PathValue<T, Path<T>>);
    }

    const fillInAddress = useCallback(() => {
        const place = autocomplete.current?.getPlace();
        const GoogleMapsValuesMap: {
            [key in string]: keyof Address;
        } = {
            street_number: 'streetNumber',
            route: 'route',
            postal_code: 'postalCode',
            locality: 'city',
            administrative_area_level_2: 'province',
        };
        if (place) {
            resetForm();

            for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
                const componentType = component.types[0];

                if (GoogleMapsValuesMap[componentType]) {
                    setValue(
                        `${buildBasePath(basePath)}${GoogleMapsValuesMap[componentType]}` as Path<T>,
                        component.long_name as PathValue<T, Path<T>>,
                    );
                }
            }
        }
    }, [setValue]);

    const initAutocomplete = useCallback(() => {
        const addressInput = document.getElementById('route') as HTMLInputElement;
        autocomplete.current = new window.google.maps.places.Autocomplete(addressInput, {
            componentRestrictions: { country: 'es' },
            fields: ['address_components'],
            types: ['address'],
        });
        autocomplete.current.addListener('place_changed', fillInAddress);
    }, [fillInAddress]);

    useEffect(() => {
        if (window?.google) {
            initAutocomplete();
        }
    }, [window.google]);

    useLayoutEffect(() => {
        window['initMap'] = () => {};
    }, []);

    return (
        <>
            {children}
            <Script
                src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&libraries=places&callback=initMap`}
            ></Script>
        </>
    );
}
