import { DropdownButton } from '@/components/Button';
import { useStorage } from '@/hooks';
import { tierIcons } from '@/lib/shared/DealsData';
import { objectToQueryString } from '@/lib/utils/queryParams';
import { isNil } from '@/lib/utils/values';
import { Situation, SituationC2C, Stage, StageC2C, Tier } from '@/types';
import { Statistics } from '@/types/statistics';
import { CatalogueVehicleSubStatuses } from '@/types/Vehicle';
import { DashboardViewSkeleton, useDashboardUsersMutation } from '@/views';
import { C2CAcquisitionSection, C2CSalesSection, PaidSection } from '@/views/dashboard/sections';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

export interface DashboardRedirectProps {
    readonly tier: Tier;
    readonly filters: {
        situation: Situation | SituationC2C;
        stage?: Stage | StageC2C;
        subStatuses?: CatalogueVehicleSubStatuses;
        salesAgentUuid?: string;
        processorAgentUuid?: string;
        label?: string;
    };
}

interface DashboardViewV2Props {
    readonly data: Statistics;
}

export function DashboardView({ data }: DashboardViewV2Props) {
    const router = useRouter();
    const { getItem, setItem } = useStorage();
    const { mutateAsync: useDashboardUsers, isPending } = useDashboardUsersMutation();

    const tiers = ['PAID', 'C2C'];
    const [selectedDealTier, setSelectedDealTier] = useState<string>(
        isNil(getItem('dealTier')) || ['RENT', 'ALL'].includes(getItem('dealTier')) ? 'PAID' : getItem('dealTier'),
    );

    const handleRedirect = (props: DashboardRedirectProps) => {
        setItem('dealTier', props.tier);
        setItem('dealsInitialParams', objectToQueryString(props.filters));

        router.push('/deals');
    };

    const handleRedirectVehicles = (props: DashboardRedirectProps) => {
        setItem('dealTier', props.tier);
        setItem('vehiclesInitialParams', objectToQueryString(props.filters));

        router.push('/vehicles');
    };

    return (
        <div className="space-y-8 gap-8">
            <div className="flex items-center gap-8">
                <div className="flex gap-8 items-center pb-6">
                    <h1 className="font-semibold text-2xl">Panel principal</h1>
                    <DropdownButton
                        className="w-8/12 justify-between min-w-fit"
                        type={'button'}
                        color="light-gray"
                        variant="outline"
                        size="small"
                        label={`${tierIcons[selectedDealTier]} ${selectedDealTier}`}
                    >
                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                            {tiers.map((tier) => (
                                <button
                                    key={tier}
                                    className="block px-4 py-2 w-full text-start hover:bg-gray-100"
                                    onClick={() => {
                                        if (selectedDealTier !== tier) useDashboardUsers({ tier });
                                        setSelectedDealTier(tier);
                                        setItem('dealTier', tier);
                                    }}
                                >
                                    {`${tierIcons[tier]} ${tier}`}
                                </button>
                            ))}
                        </ul>
                    </DropdownButton>
                </div>
            </div>
            {isPending && <DashboardViewSkeleton title="Cargando..." />}
            {selectedDealTier === 'PAID' && !isPending && <PaidSection data={data} redirectToDeals={handleRedirect} />}
            {selectedDealTier === 'C2C' && !isPending && (
                <div className="flex w-full gap-8">
                    <div className="w-1/2">
                        <C2CAcquisitionSection data={data} redirectToVehicles={handleRedirectVehicles} />
                    </div>
                    <div className="w-1/2">
                        <C2CSalesSection data={data} redirectToDeals={handleRedirect} />
                    </div>
                </div>
            )}
        </div>
    );
}
